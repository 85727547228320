import { getModuleFileInfo } from "api/module-file";
import { find, isNumber, isPlainObject } from "lodash-es";
import React, { memo, useContext, useMemo } from "react";
import { useQuery } from "react-query";
import useLang from "utils/hooks/useLang";
import { safeJSONParse } from "utils/transform/json";
import tuple from "utils/types/tuple";

import Image from ".";
import RemainingHeightContext from "components/Scaffold/RemainingHeightContext";

interface Props {
  id: string;
  fileId: string;
  module: string;
  showFileInfo?: boolean;
}

export const getSafeFileAttributes = (value: any): Record<string, string> => {
  const parsedValue = safeJSONParse(value, {});
  return isPlainObject(parsedValue) ? parsedValue : {};
};

const dimension = 800;
const displayDimension = 400;

function ImageUid({ id, fileId, module, showFileInfo }: Props) {
  const [langKey] = useLang();
  const { data } = useQuery(
    tuple(["getModuleFileInfo", { id, module }]),
    getModuleFileInfo,
    {
      staleTime: 3600000,
      enabled: !!(showFileInfo && id && fileId && module),
    }
  );

  const fileData = useMemo(() => {
    return find(data?.files, (s) => s.uid === fileId);
  }, [data?.files, fileId]);

  const title = getSafeFileAttributes(fileData?.title)[langKey];
  const description = getSafeFileAttributes(fileData?.description)[langKey];

  const { removed, focused } = useContext(RemainingHeightContext);

  if (!focused || removed) {
    return (
      <div
        className="img-placeholder"
        style={{
          background: "#e0e0e0",
          aspectRatio:
            isNumber(fileData?.width) && isNumber(fileData?.height)
              ? `${fileData?.width} / ${fileData?.height}`
              : "",
        }}
      />
    );
  }

  return (
    <>
      <Image
        width={dimension}
        height={dimension}
        widthDisplay={displayDimension}
        heightDisplay={displayDimension}
        alt={`cms-preview-${id}`}
        image={{
          width: dimension,
          height: dimension,
          id: id,
          module_name: module,
          uid: fileId,
        }}
        key={fileId}
        type={undefined}
        uuid
      />
      {showFileInfo && (title || description) && (
        <div className="text-xs" style={{ paddingTop: 6 }}>
          {title && (
            <div>
              <b>{title}</b>
            </div>
          )}
          {description && <div>{description}</div>}
        </div>
      )}
    </>
  );
}

export default memo(ImageUid);
